import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { DataContext } from '../layout/Default';
import logoIcon from '../../assets/images/logo-icon.svg';

function Header() {
  const { sessionData } = useContext(DataContext);

  const title = sessionData?.pageData?.headerText || '';
  const salesFacing = sessionData?.pageData?.salesFacing || false;

  const getTitle = () => {
    const title = sessionData?.pageData?.headerText || '';
    const titleArr = title.split(' ');
    const index = title.indexOf(titleArr[Math.round(titleArr.length / 2)]);
    if (index > 0) {
      return (
        <>
          {title.substring(0, index)}
          <span className="text-success">
            {title.substring(index, title.length)}
          </span>
        </>
      );
    }

    return title;
  };

  return (
    <Navbar
      sticky="top"
      className="defaultHeader"
      expand={false}
      bg={salesFacing && 'blue-100'}
    >
      <Container>
        <div className="navbar-row">
          <div className="navbar-header">
            {title && (
              <div className="title">
                <h1 className="title-text">{getTitle()}</h1>
              </div>
            )}

            {salesFacing && (
              <div className="sales-flag">
                <span>Sales Associate</span>
              </div>
            )}
          </div>
          <a href="/" className="navbar-brand" tabIndex="-1">
            <img
              src={logoIcon.src}
              alt="logo icon"
              className="img-responsive"
            />
          </a>
        </div>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {};

export default Header;

import React, { useContext, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import logoIcon from '../../assets/images/powered-by.png';
import { DataContext } from '../layout/Default';
import { useFormActionSubmit } from '../../utils/hooks';

function NavigationBtn({ label, triggerOnEnter = false, ...btnProps }) {
  return <Button {...btnProps}>{label}</Button>;
}

function NavigationBts(navigationType = 'default', navProperties = {}) {
  const formik = useFormikContext();
  const submitAction = useFormActionSubmit();
  if (!formik) {
    return null;
  }

  const closeBtn = () => ({
    label: navProperties?.close?.label || <i className="fa-solid fa-xmark" />,
    variant: 'outline-danger',
    className: 'btn-cancel btn-sm',
    type: 'button',
    onClick: () => {
      if (navProperties?.close?.onClick) {
        navProperties?.close?.onClick();
      } else {
        const action = navProperties?.close?.action || 'close';
        submitAction(action);
      }
    },
  });

  const continueBtn = () => ({
    label: navProperties?.continue?.label || 'Continue',
    variant: 'success',
    className: 'btn-continue btn-sm',
    triggerOnEnter: true,
    onClick: () => {
      if (navProperties?.continue?.onClick) {
        navProperties?.continue?.onClick();
      } else {
        const action = navProperties?.continue?.action || 'continue';
        formik.setFieldValue('action', action);
        formik.submitForm();
      }
    },
  });

  const backBtn = () => ({
    label: navProperties?.back?.label || (
      <i className="fa-solid fa-arrow-left" />
    ),
    variant: 'success',
    className: 'mr-2 btn-sm',
    onClick: () => {
      if (navProperties?.back?.onClick) {
        navProperties?.back?.onClick();
      } else {
        const action = navProperties?.back?.action || 'back';
        submitAction(action);
      }
    },
  });

  const nextBtn = () => ({
    label: navProperties?.next?.label || (
      <i className="fa-solid fa-arrow-right" />
    ),
    variant: 'success',
    className: 'btn-sm',
    triggerOnEnter: true,
    onClick: () => {
      if (navProperties?.next?.onClick) {
        navProperties?.next?.onClick();
      } else {
        const action = navProperties?.next?.action || 'next';
        formik.setFieldValue('action', action);
        formik.submitForm();
      }
    },
  });

  const cancelApplication = () => ({
    label: navProperties?.cancel?.label || 'Cancel Application',
    variant: 'outline-danger',
    className: 'btn-cancel btn-sm',
    onClick: () => {
      if (navProperties?.cancel?.onClick) {
        navProperties?.cancel?.onClick();
      } else {
        const action = navProperties?.cancel?.action || 'cancel';
        submitAction(action);
      }
    },
  });

  const submitApplication = () => ({
    label: navProperties?.submit?.label || 'Submit Application',
    variant: 'success',
    className: 'btn-continue btn-sm',
    triggerOnEnter: true,
    onClick: () => {
      if (navProperties?.submit?.onClick) {
        navProperties?.submit?.onClick();
      } else {
        const action = navProperties?.submit?.action || 'submit';
        formik.setFieldValue('action', action);
        formik.submitForm();
      }
    },
  });

  const loginBtn = () => ({
    label: 'Login',
    variant: 'success',
    onClick: () => {
      formik.submitForm();
    },
  });

  const getCustomNavigation = () => {
    const template = {
      left: [],
      right: [],
    };

    const getBtnProps = (btnProps) => {
      const { submitType, action, ...rest } = btnProps;
      return {
        label: 'Login',
        variant: 'outline-secondary',
        triggerOnEnter: true,
        className: 'btn btn-sm',
        onClick: () => {
          if (action && submitType) {
            if (submitType === 'submit') {
              formik.setFieldValue('action', action);
              formik.submitForm();
            }
            if (submitType === 'action') {
              submitAction(action);
            }
          }
        },
        ...rest,
      };
    };

    if (navProperties?.left?.length) {
      template.left = navProperties.left.map((btnProps, i) => (
        <NavigationBtn
          {...getBtnProps(btnProps)}
          // eslint-disable-next-line react/no-array-index-key
          key={`footer-custom-btn${i}`}
        />
      ));
    }
    if (navProperties?.right?.length) {
      template.right = navProperties.right.map((btnProps, i) => (
        <NavigationBtn
          {...getBtnProps(btnProps)}
          // eslint-disable-next-line react/no-array-index-key
          key={`footer-custom-btn${i}`}
        />
      ));
    }
    return template;
  };

  const navigationTypes = {
    none: {
      left: [],
      right: [],
    },
    close_only: {
      left: [<NavigationBtn {...closeBtn()} />],
      right: [],
    },
    continue: {
      left: [<NavigationBtn {...closeBtn()} />],
      right: [<NavigationBtn {...continueBtn()} />],
    },
    no_back: {
      left: [<NavigationBtn {...closeBtn()} />],
      right: [<NavigationBtn {...nextBtn()} />, <div />],
    },
    agreement: {
      left: [<NavigationBtn {...cancelApplication()} />],
      right: [<NavigationBtn {...submitApplication()} />],
    },
    submit_only: {
      left: [],
      right: [<NavigationBtn {...submitApplication()} />],
    },
    default: {
      left: [<NavigationBtn {...closeBtn()} />],
      right: [
        <NavigationBtn {...nextBtn()} />,
        <NavigationBtn {...backBtn()} />,
      ],
    },
    custom: getCustomNavigation(),
    login: {
      left: [],
      right: [<NavigationBtn {...loginBtn()} />],
    },
  };

  return navigationType
    ? navigationTypes[navigationType]
    : navigationTypes.default;
}

function Footer() {
  const { sessionData } = useContext(DataContext);
  const { pathname } = useRouter();
  const navigationType = sessionData?.pageData?.navigationType || 'default';
  const navProperties = sessionData?.pageData?.navProperties || {};

  let navigation = NavigationBts(navigationType, navProperties);

  function enterKeyListener(e) {
    const code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      const submitButton = navigation.right
        .filter(({ props }) => props.triggerOnEnter)
        .shift();

      if (typeof submitButton?.props?.onClick === 'function') {
        submitButton.props.onClick();
      }
    }
  }

  if (pathname === '/login') {
    navigation = NavigationBts('login');
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', enterKeyListener);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('keydown', enterKeyListener);
    };
  }, [navigationType]);

  return (
    <footer className="footer sticky-bottom">
      <div className="container">
        <Row>
          <Col
            xs={6}
            sm={4}
            className="d-flex align-items-center justify-content-start flex-row-reverse"
          >
            {navigation?.right?.length > 0 &&
              navigation.right.map((btn, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="d-flex w-100" key={`right-col-btn-${index}`}>
                  {btn}
                </div>
              ))}
          </Col>

          <Col
            sm={4}
            className="footer-logo-block d-flex align-items-center justify-content-center"
          >
            <a href="/" className="footer-logo text-center" tabIndex="-1">
              <img src={logoIcon.src} alt="log" className="img-responsive" />
            </a>
          </Col>

          <Col
            xs={6}
            sm={4}
            className="d-flex align-items-center justify-content-start"
          >
            {navigation?.left?.length > 0 &&
              navigation.left.map((btn, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="d-flex w-100" key={`left-col-btn-${index}`}>
                  {btn}
                </div>
              ))}
          </Col>
        </Row>
      </div>
    </footer>
  );
}

Footer.propTypes = {};
export default Footer;
